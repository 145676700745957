.coin-hero-header-root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px auto 0px auto;
  padding: 20px;
}
.coin-hero-header-root > div:first-child {
  width: 55%;
}
.coin-hero-header-root > div:last-child {
  width: 45%;
}

@media only screen and (max-width: 968px) {
  .coin-hero-header-root {
    flex-direction: column;
    margin: 50px auto 0px auto;
    gap: 80px;
  }
  .coin-hero-header-root > div {
    width: 100% !important;
  }
}
