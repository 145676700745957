.main-heading {
  text-align: center;
  font-size: 82px;
  font-weight: 400;
  font-family: tahu;
}

.main-paragraph {
  text-align: center;
  font-size: 18px;
}
.top10DaCrazyHeading {
  font-family: tahu;
  text-align: center;

  font-size: 62px;
}
.leaderboard-container {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;

  overflow: hidden;
}
.leaderboardHead {
  margin-top: 60px;
  text-align: center;
}

.leaderboard-header {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  background-color: lightgrey;
  font-weight: bold;
  text-align: center;
}

.leaderboard-row {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  /* border-bottom: 1px solid #eee; */
  text-align: center;
}

.leaderboard-column {
  flex: 1;
}

.rank-image {
  height: 24px;
}

.responsive-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 16px;
  max-width: 1280px;
  margin: auto;
  width: 100%;
  padding: 20px;
}

.card {
  flex: 1 1 calc(33.333% - 16px);
  max-width: calc(33.333% - 16px);
  padding: 16px;
  text-align: center;
  border: none;
}

.card-image {
  width: 100px;
  margin: 0 auto 16px;
}

.card h3 {
  margin-bottom: 12px;
  font-size: 2.75rem;
  font-family: tahu;
}

.card p {
  font-size: 1rem;
  line-height: 1.5;
}

.card p span {
  display: block;
  margin-bottom: 8px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .card {
    flex: 1 1 calc(50% - 16px);
    max-width: calc(50% - 16px);
  }
}

@media (max-width: 480px) {
  .card {
    flex: 1 1 100%;
    max-width: 100%;
  }
}

.responsive-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1280px;
  margin: 50px auto;
  gap: 16px;
}

.inner-component {
  flex: 1 1 calc(33.333% - 16px);
  max-width: calc(33.333% - 16px);
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: space-between;
  padding: 16px;
}

.inner-component .content {
  flex: 1;
  background-color: #f1f1f1;
  padding: 16px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.inner-component .logo {
  max-width: 150px;
  margin-right: 12px;
}

.action {
  display: flex;
  align-items: center;
  justify-content: center;
}

.action-button {
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .inner-component {
    flex: 1 1 calc(50% - 16px);
    max-width: calc(50% - 16px);
  }
}

@media (max-width: 480px) {
  .inner-component {
    flex: 1 1 100%;
    max-width: 100%;
  }
}

/* iconic tabs */

.iconic-tabs-container {
  display: flex;
  justify-content: center;
  gap: 60px;
  margin: 20px 0;
}

.iconic-tab {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.tab-icon {
  width: 50px;
  height: 50px;
  transition: transform 0.3s ease-in-out;
}

.tab-text {
  margin-top: 0px;
  font-size: 14px;
  text-align: center;
  color: #000;
}

.tab-underline {
  position: absolute;
  bottom: 5px;
  height: 3px;
  width: 16px;
  opacity: 0;
  background-color: #000;
}

.iconic-tab:hover .tab-underline {
  opacity: 1;
}

.active .tab-underline {
  opacity: 1;
}

.iconic-tab:hover .tab-icon {
  transform: scale(1.1);
}

.video-gallery {
  display: flex;
  /* flex-direction: column; */
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
}
.video-container {
  max-width: 600px;
  width: 100%;
}
