.crazyYoutubehover {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.crazyYoutubehover:hover {
  opacity: 0.8;
}
