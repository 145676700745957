.backdrops {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1111111111;
}

.modals {
  max-width: 427px;
  width: 90%;
  background-color: #143156;
  border-radius: 0px;
  padding: 30px 20px;
  position: relative;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  position: absolute;
  top: 0px;
  right: -100px;
}
.close-button:hover {
  transform: scale(1.1);
}
.content {
  margin-top: 20px;
  text-align: center;
}

.footer {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.footer a,
.footer button {
  flex: 1;
}

@media screen and (max-width: 600px) {
  .modals {
    max-width: 90%;
  }
  .close-button {
    top: -50px;
    right: 0px;
  }
}
