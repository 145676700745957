.main-heading {
  text-align: center;
  font-size: 72px;
  font-weight: 600;
}

.top10DaCrazyHeading {
  font-family: tahu;
  text-align: center;

  font-size: 62px;
}
.leaderboard-container {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;

  overflow: hidden;
}
.leaderboardHead {
  margin-top: 60px;
  text-align: center;
}

.leaderboard-header {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  background-color: lightgrey;
  font-weight: bold;
  text-align: center;
}

.leaderboard-row {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  /* border-bottom: 1px solid #eee; */
  text-align: center;
}

.leaderboard-column {
  flex: 1;
}

.rank-image {
  height: 24px;
}

.responsive-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 16px;
  max-width: 1280px;
  margin: auto;
  width: 100%;
  padding: 20px;
}

.card {
  flex: 1 1 calc(33.333% - 16px);
  max-width: calc(33.333% - 16px);
  padding: 16px;
  text-align: center;
  border: none;
}

.card-image {
  width: 100px;
  margin: 0 auto 16px;
}

.card h3 {
  margin-bottom: 12px;
  font-size: 2.75rem;
  font-family: tahu;
}

.card p {
  font-size: 1rem;
  line-height: 1.5;
}

.card p span {
  display: block;
  margin-bottom: 8px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .card {
    flex: 1 1 calc(50% - 16px);
    max-width: calc(50% - 16px);
  }
}

@media (max-width: 480px) {
  .card {
    flex: 1 1 100%;
    max-width: 100%;
  }
}

.responsive-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1280px;
  margin: 50px auto;
  gap: 16px;
}

.inner-component {
  flex: 1 1 calc(33.333% - 16px);
  max-width: calc(33.333% - 16px);
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: space-between;
  padding: 16px;
}

.inner-component .content {
  flex: 1;
  background-color: #f1f1f1;
  padding: 16px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.inner-component .logo {
  max-width: 150px;
  margin-right: 12px;
}

.action {
  display: flex;
  align-items: center;
  justify-content: center;
}

.action-button {
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .inner-component {
    flex: 1 1 calc(50% - 16px);
    max-width: calc(50% - 16px);
  }
}

@media (max-width: 480px) {
  .inner-component {
    flex: 1 1 100%;
    max-width: 100%;
  }
}
